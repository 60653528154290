<template>
  <div class="home">
    <div class="flex items-center justify-center min-h-screen bg-gray-100 px-6">
      <div class="text-center w-full max-w-4xl mx-auto py-12">
        <h1 class="text-5xl font-extrabold text-gray-900 mb-6">Website Under Development</h1>
        <p class="text-xl text-gray-600 mb-8">We’re currently working hard to improve your experience. Please check back
          soon!</p>

        <p class="text-xl text-gray-800 mb-6">Here's a preview of what's coming:</p>

        <ul class="mx-auto w-fit list-disc list-inside text-gray-700 text-lg mb-6 space-y-2 text-start">
          <li>New features and functionality</li>
          <li>Improved user interface and navigation</li>
          <li>Faster load times and better performance</li>
        </ul>

        <p class="text-lg text-gray-500 mt-4">Thank you for your patience. We look forward to sharing our new site with
          you!</p>
      </div>
    </div>

  </div>
</template>