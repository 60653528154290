<template>
    <footer class="bg-black text-white">
        <div class="px-10 py-8 lg:flex  justify-around ">
            <!-- Left Section -->
            <div class="mb-6 md:mb-0 text-start">
                <h1 class="text-2xl font-light">KRISHIVANI</h1>
                <p class="mt-4">
                    Shalimar, <br />
                    SKUAST-K, Srinagar, Jammu and Kashmir 190025
                </p>
                <p class="mt-4">Contact:</p>
                <p>1234 5678 90</p>
                <p>krishivani@example.com</p>
                <div class="mt-4 flex space-x-4">
                    <!-- Social Icons -->
                    <a href="#" aria-label="Facebook" class="hover:text-gray-400">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="#" aria-label="Instagram" class="hover:text-gray-400">
                        <i class="fab fa-instagram"></i>
                    </a>
                    <a href="#" aria-label="LinkedIn" class="hover:text-gray-400">
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a href="#" aria-label="YouTube" class="hover:text-gray-400">
                        <i class="fab fa-youtube"></i>
                    </a>
                </div>
            </div>


            <!-- Right Section -->
            <div class="flex lg:items-start lg:mt-0 mt-12">

                <ul class="space-y-4 text-md text-start lg:px-10 ">
                    <li>
                        <a href="#" class="hover:text-gray-400">About Us</a>
                    </li>
                    <li>
                        <a href="#" class="hover:text-gray-400">Upload Article</a>
                    </li>
                    <li>
                        <a href="#" class="hover:text-gray-400">Publications</a>
                    </li>
                    <li>
                        <a href="#" class="hover:text-gray-400">Account</a>
                    </li>

                </ul>
                <ul>
                    <li>
                        <a href="#" class="hover:text-gray-400">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="border-t border-gray-700 py-4 pb-10">
            <div class="container mx-auto px-4 flex flex-col  justify-between items-center">
                <p class="text-sm">
                    &copy; 2024 Krishivani. All rights reserved.
                </p>
                <p class="text-sm mt-4 md:mt-0">
                    Designed and Maintained by PINOVA SERVICES PVT. LTD.
                </p>
            </div>
        </div>
    </footer>



</template>